<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import Multiselect from "vue-multiselect";
import { required, requiredIf } from "vuelidate/lib/validators";
import EmptyList from "@/components/widgets/empty_list"
import Draggable from 'vuedraggable'
import _debounce from 'lodash.debounce';

import {
  strategyMethods,
  commonMethods,
  propertyMethods,
  projectMethods
} from "@/state/helpers";

/**
 * Edit campaign Component
 */
export default {
	page: {
		title: "Custom Strategy",
		meta: [
			{
				name: "description",
				content: appConfig.description,
			},
		],
	},
	components: {
		Layout,
		PageHeader,
    Multiselect,
    EmptyList,
    Draggable		
	},
	data() {
		return {
			title: "",
			items: [],
			isLoading: false,
      submitted: false,
			strategy: {
        settings:{}
      },
      types: [
        {id: 'one_strategy', label: this.$t('custom_strategies.type_one_strategy')},
        {id: 'mixed', label: this.$t('custom_strategies.type_mixed')},
        {id: 'list', label: this.$t('custom_strategies.type_list')},
        {id: 'ranking', label: this.$t('custom_strategies.type_ranking')}
      ],
      auxStrategy: null,
      recoStrategies:[],
      pageTypes: [
        {id: "product_detail", label: this.$t("personalizations.page_type_product_detail")},
        {id: "cart", label: this.$t("personalizations.page_type_cart")},
        {id: "checkout", label: 'Checkout'},
        {id: "category", label: this.$t("personalizations.page_type_category") },
        {id: "home", label: this.$t("personalizations.page_type_home") },
        {id: "product_listing", label: this.$t("personalizations.page_type_product_listing") },
        {id: "search", label: this.$t("personalizations.page_type_search") },
        {id: "any", label: this.$t("personalizations.page_type_any") }
      ],
      auxPageType: {
        id: "any", label: this.$t("personalizations.page_type_any")
      },
      empty_slots_config:{
          "title" : this.$t('custom_strategies.empty_slots_title'),
          "subtitle": this.$t('custom_strategies.empty_slots_subtitle'),
          "buttonText" : this.$t('custom_strategies.assign_slots'),
          icon: "fa-cubes"
      },
      drag: false,
      showSlotAssignModal:false,
      auxSlot:{},
      editSlot: false,
      editIndex: -1,
      affinity_Types:[],
      selectedProduct:null,
      selectedProducts: [],
      products:[],
      empty_items_config:{
          "title" : this.$t('custom_strategies.emtpty_list_items_title'),
          "subtitle": this.$t('custom_strategies.emtpty_list_items_subtitle'),
          icon: "fa-cubes"
      },
      selectedItem: null,
      itemImagesModal: false,
      sortReco: [
        {
          id: 'fixed',
          label: this.$t('custom_strategies.sort_fixed')
        },
        {
          id: 'affinity',
          label: this.$t('custom_strategies.sort_affinity')
        },
        {
          id: 'shuffle',
          label: this.$t('custom_strategies.sort_shuffle')
        }
      ],
      auxSort: {
          id: 'fixed',
          label: this.$t('custom_strategies.sort_fixed')
        },
    }
  },
  validations: {
    strategy: {
      name: {
        required,
      },
      strategy: {
        required: requiredIf((model)=> { return model.type?.id == 'one_strategy'}),
      },
    }
  },
	mounted() {
		this.loadProperties();

		const strategyId = this.$route.query.id;
		
		if(strategyId){
      let loader = this.$loading.show();
      this.title = this.$t('custom_strategies.edit_custom_strategy');
      this.getCustomStrategy(strategyId).then((res)=>{
        this.strategy = res.data;
        this.auxPageType = this.strategy.pageType ? this.pageTypes.filter(p=>p.id == this.strategy.pageType)[0] : this.auxPageType;
        this.strategy.type = this.strategy.type ? this.types.filter (p=>p.id == this.strategy.type)[0] : null;
        this.auxStrategy = this.strategy.strategy;
        this.auxSort = this.strategy.settings.sort ? this.sortReco.filter(s=> s.id== this.strategy.settings.sort) : this.auxSort;
        this.loadRecoStrategies();
        if(this.strategy.type.id == 'list' && this.strategy.strategy?.items.length >0){
          this.getListStrategyItems()
        }

      }).catch(()=>{

      }).finally(()=>{
        loader.hide();
      })
			
		}else{
      this.$router.push({
            path: `/404`,
            }
        ).catch((error) => {
            // eslint-disable-next-line no-console
            console.log(error)
        });
    }
		
	},
	created() {
		this.debounceProducts = _debounce(this.searchProduct.bind(this), 1000);
	},
  computed: {
    getTotalSlots() {
      let total = 0;

      this.strategy.slots?.forEach(s=> {
        total+= parseInt(s.slots)
      })
      return total;
    },
  },
	methods: {
    ...strategyMethods,
    ...commonMethods,
    ...propertyMethods,
    ...projectMethods,

    loadProperties(){
      let loader = this.$loading.show();
      const params = {
        q : `project=${localStorage.getItem("current_project")}`
      }
      this.getProperties(params).then((properties)=>{
        const property_products = properties.filter(p=> p.entity == 'product');
        this.affinity_Types = property_products.filter(p=> p.type== 'list');

				this.affinity_Types.push({
          type : 'product_category',
          name: this.$t("audiences.audience_conditions_fields_interaction_products_with_category"),
          column_name: 'product_category'
        });
      }).catch(()=>{

      }).finally(()=>{
        loader.hide();
      })
    },
    async loadRecoStrategies(){
      this.recoStrategies = await this.getRecommendationStrategies();

      if(this.auxPageType && this.auxPageType.id != 'any'){
        this.recoStrategies = this.recoStrategies.filter(s => (!s.pages_restricted || s.pages_restricted.length == 0) || (s.pages_restricted.length > 0 && s.pages_restricted.filter(p=> p === this.auxPageType.id).length == 0) )
      }
    },
    onConfirmAddSlotClicked(){
      if(this.editSlot){
        this.$set(this.strategy.slots, this.editIndex, this.auxSlot);
      }else{
        this.strategy.slots.push(this.auxSlot);
      }
      
      this.showSlotAssignModal = false;
    },
    onNewSlotAssignmentClicked(){
      this.editSlot = false;
      if(!this.strategy.slots)
        this.strategy.slots = [];

      this.auxSlot = {
        slots: '1',
        strategy: { id: "user_reco", name: this.$t('personalizations.experience_variable_strategy_user_reco')}
      }
      this.showSlotAssignModal = true;
    },
    onSlotTypeChecked(){

    },

    onCancelClicked(){
      this.$router.push({
            path: `/custom-strategies`,
            }
        ).catch((error) => {
            // eslint-disable-next-line no-console
            console.log(error)
        });
    },
    onConfirmClicked(){
      this.submitted = true;

      this.$v.$touch();

			if (this.$v.$invalid) {
				return;
			}

      let loader = this.$loading.show();
      
      if(this.strategy.type.id == 'one_strategy'){
        this.strategy.strategy = this.auxStrategy;
      }else if(this.strategy.type.id=='list'){
        if(!this.strategy.strategy)
        this.strategy.strategy = {}; 
        this.strategy.strategy.items = this.selectedProducts.map((p)=>p.data.id);
      }
      
      let payload = Object.assign({}, this.strategy);
      payload.type = this.strategy.type.id;

      this.updateCustomStrategy(payload).then(()=>{
        this.$notify({ type: 'success', text: this.$t("custom_strategies.update_custom_strategy_success"),title:  this.$t("custom_strategies.title") });
      }).catch(()=>{
        this.$notify({ type: 'error', text: this.$t('custom_strategies.update_custom_strategy_error'),title:  this.$t('custom_strategies.title') });
      }).finally(()=>{
        loader.hide();
      })

    },
    onStrategyChanged(){
      this.strategy.strategy = this.auxStrategy;
    },

    onPageTypeChanged(){
      this.strategy.pageType = this.auxPageType.id;
      this.loadRecoStrategies();
    },

    oSortChanged(){
      this.strategy.sort = this.auxSort.id;
    },

    onStrategyTypeChanged(){
      if(this.strategy.type.id != 'one_strategy'){
        this.strategy.strategy = null;
        this.auxStrategy = null;
      }
    },
    onEditSlotClicked(data, i){
      this.auxSlot = Object.assign({}, data);
      this.showSlotAssignModal = true;
      this.editSlot = true;
      this.editIndex = i;
    },
    onRemoveSlotClicked(index){
      this.strategy.slots.splice(index,1);
    },
    searchProduct(query){
			
			this.isLoading = true;

			if(query){
				this.products=[];
				const params ={
					q: `whereObj={"project":"${localStorage.getItem("current_project")}", "data.active" : 1, "$or": [{"data.name" : {"$regex" : ".*${query}*." , "$options": "i"} }, {"data.sku" : {"$regex" : ".*${query}*." , "$options": "i"}} ]}`
				}

				this.getItems(params).then((products)=>{
					if(products&& products.data){
						this.products= products.data;
					}
					this.isLoading = false;
				})
			}else{
				this.products = [];
				this.isLoading = false;
				this.selectedProduct=null;
			}
		},
    nameWithSKU(p) {
			return `${p.data.sku} — [${p.data.name}]`
		},
    onSelectProductsClicked(p){
      if(this.selectedProducts.filter(pr=>pr.data.id==p.data.id).length==0){
        this.selectedProducts.push(p);
      }
    },
    onItemImagesClicked(item){
      this.selectedItem = item.data;
      this.itemImagesModal = true;
    },
    getListStrategyItems(){
      let loader = this.$loading.show();

      const params ={
					q: `whereObj={"project":"${localStorage.getItem("current_project")}", "data.id" : {"$in": [${this.strategy.strategy.items.map(item => `"${item}"`)}]} }`
				}

				this.getItems(params).then((products)=>{
          if(products&& products.data){
						this.selectedProducts= products.data;
					}
        }).finally(()=>{
          loader.hide();
        })

    },
    onRemoveProduct(data){
      this.selectedProducts = this.selectedProducts.filter(p=>p.data.id!=data.data.id);
    } 
  }
	
};
</script>

<template>
	<Layout>
		<PageHeader :title="title" :items="items" />
		<div class="checkout-tabs">
			<b-tabs
				pills
				vertical
				nav-class="p-0"
				nav-wrapper-class="col-xl-2 col-sm-3">
				<b-tab active>
					<template v-slot:title>
						<i class="bx bxs-briefcase-alt d-block check-nav-icon mt-4 mb-2"></i>
						<p class="fw-bold mb-4">{{$t('campaigns.new_campaign_basicInfo')}}</p>
					</template>
					<b-card-text>
						<div class="card">
							<div class="card-body">
								<h4 class="card-title">{{$t('custom_strategies.basic_info')}}</h4>
								<p class="card-title-desc">{{$t('custom_strategies.basic_info_subtitle')}}</p>
								<div class="row">
									<div class="col">
										<div class="mb-3">
											<label for="campaignName">{{$t('custom_strategies.name')}}*</label>
											<input
												id="campaignName"
												v-model="strategy.name"
												name="name"
												type="text"
                        :placeholder="$t('custom_strategies.name_placeholder')"
												class="form-control"
												:class="{'is-invalid': submitted && $v.strategy.name.$error,}"
											/>
											<div v-if="submitted && !$v.strategy.name.required" class="invalid-feedback">
                        {{$t('custom_strategies.name_required')}}
											</div>
										</div>
									</div>
								</div>
								<div class="row" v-if="strategy.type?.id != 'list'">
									<div class="col">
										<div class="mb-3">
											<label for="role">{{$t('custom_strategies.page_type')}}*</label>
											<multiselect 
												v-model="auxPageType"
												:options="pageTypes"
												:selectLabel="$t('common.select')"
												:deselectLabel="$t('common.deselect')"
												:multiple="false"
                        :allow-empty="false"
												track-by="id" 
												label="label"
												:showNoResults="false"
                        @input="onPageTypeChanged">
											</multiselect>
										</div>
									</div>
								</div>
                <div class="row">
									<div class="col">
										<div class="mb-3">
											<label for="role">{{$t('custom_strategies.type')}}*</label>
											<multiselect 
												v-model="strategy.type"
												:options="types"
												:selectLabel="$t('common.select')"
												:deselectLabel="$t('common.deselect')"
												:multiple="false"
                        :allow-empty="false"
												track-by="id" 
												label="label"
												:showNoResults="false"
                        @input="onStrategyTypeChanged">
											</multiselect>
										</div>
									</div>
								</div>
                <div class="row" v-if="strategy.type?.id == 'one_strategy'">
									<div class="col">
										<div class="mb-3">
											<label for="role">{{$t('custom_strategies.strategy')}}*</label>
											<multiselect 
												v-model="auxStrategy"
												:options="recoStrategies"
												:selectLabel="$t('common.select')"
												:deselectLabel="$t('common.deselect')"
                        :placeholder="$t('custom_strategies.strategy_placeholder')"
												:multiple="false"
                        :allow-empty="false"
												track-by="id" 
												label="name"
												:showNoResults="false"
                        :class="{'is-invalid': submitted && $v.strategy.strategy.$error,}"
                        @input="onStrategyChanged">
											</multiselect>
                      <div v-if="submitted && !$v.strategy.strategy.required" class="invalid-feedback">
                        {{$t('custom_strategies.strategy_required')}}
                      </div>
										</div>
									</div>
								</div>
                <div class="row" v-if="strategy.type?.id == 'one_strategy' && strategy.strategy?.id=='user_affinity'">
                  <div class="col">
                    <div class="mb-3">
                      <label for="role">{{this.$t("personalizations.experience_variable_strategy_affinity")}}*</label>
                      <multiselect
                          :options="affinity_Types" 
                          v-model="strategy.strategy.affinity"
                          selectLabel=""
                          deselectLabel=""
                          track-by="column_name"
                          :placeholder="this.$t('personalizations.experience_variable_strategy_affinity_placeholder')"
                          label="name"
                          :allow-empty="false"
                          openDirection="bottom"
                        />
                    </div>
                  </div>
                </div>
              </div>
						</div>
					</b-card-text>
				</b-tab>
				<b-tab v-if="strategy.type?.id == 'mixed'">
					<template v-slot:title>
						<i class="bx bx-customize d-block check-nav-icon mt-4 mb-2"></i>
						<p class="fw-bold mb-4">{{$t('custom_strategies.slots')}}</p>
					</template>
					<div class="card">
						<div class="card-body">
							<h4 class="card-title">{{$t('custom_strategies.slots_title')}}</h4>
							<p class="card-title-desc mb-0">{{$t('custom_strategies.slots_subtitle')}}</p>
              <div class="text-end col" v-if="strategy.slots.length > 0">
								<button type="button" v-on:click="onNewSlotAssignmentClicked" class="btn btn-sm btn-primary mt-0 mb-3" >+ {{$t('custom_strategies.assign_slots')}}</button>
							</div>
              <EmptyList :config="empty_slots_config" v-if="strategy.type?.id == 'mixed' &&  strategy.slots?.length == 0" @onButtonClicked="onNewSlotAssignmentClicked" class="mt-5 mb-3 d-block"/>
              <Draggable v-model="strategy.slots" group="people" @start="drag=true" @end="drag=false" :forceFallback="true" v-if="strategy.type?.id == 'mixed' &&  strategy.slots?.length > 0">
							<div class="card shadow-sm" v-for="(element,index) in strategy.slots" :key="element.id" style="background-color: rgba(85,110,230,.1); margin-bottom: 15px; ">
								<div class="card-body row py-2">
									<div class="col-sm-11" style="cursor: pointer;">
                    <div class="d-inline-flex align-items-baseline">
                      <i class="mdi-drag-vertical mdi align-self-center font-size-22 me-1 text-muted " style="cursor: grab;"></i>
                      <div>
                        <h5 class="mb-0 interact font-size-16">
                          {{ element.strategy.id == 'user_affinity' ? `${element.strategy.name} - ${element.strategy.affinity.name}` : element.strategy.name}}                      
                        </h5>
                        <p class="mb-0 small"> {{ `${element.slots} ${element.slots == 1 ? 'Slot' : 'Slots'}` }}</p>
                      </div>
                    </div>
                  </div>
									<div class="col-sm-1 text-end d-flex justify-content-end align-items-baseline" style="margin: auto;">
                    <div class="d-inline-flex align-items-baseline">
                      <i class="fas fa-edit text-success me-2 interact" v-on:click="onEditSlotClicked(element,index)" />
                      <i class="fas fa-trash-alt text-danger me-2 interact" v-on:click="onRemoveSlotClicked(index)"/>
                    </div>
                  </div>
								</div>
							</div>
              </Draggable>
              <p class="fw-bold mb-0 mt-4" v-if="strategy.type?.id == 'mixed' &&  strategy.slots?.length > 0">{{ `Total: ${getTotalSlots} ${getTotalSlots == 1 ? 'Slot' : 'Slots'}` }}</p> 
            </div>
					</div>
				</b-tab>
        <b-tab v-if="false">
					<template v-slot:title>
						<i class="bx bx-filter d-block check-nav-icon mt-4 mb-2"></i>
						<p class="fw-bold mb-4">Filtros</p>
					</template>
					<div class="card">
						<div class="card-body">
							<h4 class="card-title">Filtros</h4>
							<p class="card-title-desc">Filtrar productos a recomendar</p>
							
						</div>
					</div>
				</b-tab>
        <b-tab v-if="strategy.type?.id=='list'">
          <template v-slot:title>
						<i class="bx bxs-purchase-tag-alt d-block check-nav-icon mt-4 mb-2"></i>
						<p class="fw-bold mb-4">Items</p>
					</template>
					<div class="card">
						<div class="card-body">
							<h4 class="card-title mb-1">Items</h4>
							<p class="card-title-desc mb-0">{{$t('custom_strategies.items_subtitle')}}</p>
							<div class="row mb-0">
								<div class="col-sm-6 text-sm-end">
									<b-spinner
										v-if="isLoading"
										class="m-2"
										variant="primary"
										role="status">
									</b-spinner>
								</div>
								<div class="col-sm-6">
									<div class="text-sm-end">
										<multiselect 
											v-model="selectedProduct" 
											id="products" 
											label="name" 
											track-by="_id" 
											selectLabel=""
											deselectLabel=""
											:placeholder="$t('campaigns.new_campaign_products_select')"
											open-direction="bottom" 
											:options="products" 
											:multiple="false" 
											:searchable="true" 
											:internal-search="false" 
											:clear-on-select="false" 
											:close-on-select="true" 
											:options-limit="300" 
											:limit="1" 
											:max-height="600" 
											:show-no-results="false" 
											:hide-selected="false"
											@search-change="debounceProducts"
											@input="onSelectProductsClicked"
											:custom-label="nameWithSKU">
											<template v-slot:noOptions>
												{{$t('campaigns.new_campaign_products_empty')}}
											</template>
										</multiselect>
									</div>
								</div>
							</div>
              <EmptyList :config="empty_items_config" v-if="selectedProducts.length==0" class="mt-5 mb-3 d-block"/>
              <div v-if="selectedProducts.length > 0" class="table-container">
                <table class="table align-middle  mt-3 mb-0">
                  <thead class="table-light">
                    <tr>
                      <th style="width: 20px"></th>
                      <th class="align-middle">SKU</th>
                      <th class="align-middle">{{$t('campaigns.new_campaign_products_name')}}</th>
                      <th class="align-middle" scope="col">{{$t('items.list_item_images')}}</th>
                      <th  class="align-middle" scope="col">{{$t('items.list_item_price')}}</th>
                      <th class="align-middle" scope="col">{{$t('items.list_item_status')}}</th>
                      <th></th>
                    </tr>
                  </thead>
                  
                  <Draggable v-model="selectedProducts" group="people" tag="tbody" @start="drag=true" @end="drag=false" :forceFallback="true" :class="{ 'no-select': drag }">
                    <tr v-for="element in selectedProducts" :key="element.id">
                      <td class="p-0 text-center"><i class="mdi-drag-vertical mdi align-self-center font-size-20 me-1 text-muted " style="cursor: grab;"></i></td>
                        <td>{{ element.data.sku }}</td>
                        <td style="max-width: 500px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">{{ element.data.name }}</td>
                        <td><i class= 'bx bx-show interact' v-on:click="onItemImagesClicked(element)"/></td>
                          <td>$ {{element.data.price}}</td>
                          <td>
                              <span class="badge font-size-10"
                                  :class="{
                                  'bg-success': `${element.data.active}` === true || `${element.data.active}` == 1,
                                  'bg-warning': `${element.data.active}` === false || `${element.data.active}` == 0,
                              }">{{ element.data.active || element.data.active == 1 ? $t('custom_strategies.status_active') : $t('custom_strategies.status_inactive') }}</span>
                          </td>
                          <td><i class="fas fa-trash-alt text-danger me-1 interact" v-on:click="onRemoveProduct(element)"></i></td>
                      </tr>
                  </Draggable>
                
                </table>
              </div>
            </div>
					</div>
        </b-tab>
				<b-tab>
					<template v-slot:title>
						<i class="bx bxs-wrench d-block check-nav-icon mt-4 mb-2"></i>
						<p class="fw-bold mb-4">{{$t('custom_strategies.settings')}}</p>
					</template>
					<div class="card">
						<div class="card-body">
							<h4 class="card-title">{{$t('custom_strategies.settings')}}</h4>
							<p class="card-title-desc">{{$t('custom_strategies.settings_subtitle')}}</p>
							<div class="row pt-3" v-if="strategy?.type?.id!='list'">
                <div class="col-sm-6">
                  <div class="mb-3">
                  <b-form-checkbox
                      v-model="strategy.settings.shuffle"
                      class="mb-3"
                      plain>{{$t('custom_strategies.use_shuffle')}}</b-form-checkbox>
                  </div>
                </div>
              </div> 
              <div class="row">
                <div class="col-sm-6">
                  <div class="mb-3">
                  <b-form-checkbox
                      v-model="strategy.settings.exclude_recently_purchased"
                      class="mb-3"
                      plain>{{$t('custom_strategies.exclude_recently_purchased')}}</b-form-checkbox>
                  </div>
                </div>
              </div> 
              <div class="row">
                <div class="col-sm-6">
                  <div class="mb-3">
                  <b-form-checkbox
                      v-model="strategy.settings.exclude_recently_viewed"
                      class="mb-3"
                      plain>{{$t('custom_strategies.exclude_recently_viewed')}}</b-form-checkbox>
                  </div>
                </div>
              </div> 
              <div class="row" v-if="strategy?.type?.id!='list'">
                <div class="col-sm-6">
                  <div class="mb-3">
                  <b-form-checkbox
                      v-model="strategy.settings.use_fallback"
                      class="mb-3"
                      plain>{{$t('custom_strategies.use_fallback')}}</b-form-checkbox>
                  </div>
                </div>
              </div>
              <div class="row" v-if="strategy?.settings?.use_fallback == true">
                <div class="col-sm-6">
                  <multiselect 
                    v-model="strategy.settings.fallback_strategy"
                    :options="recoStrategies"
                    :selectLabel="$t('common.select')"
                    :deselectLabel="$t('common.deselect')"
                    :placeholder="$t('custom_strategies.strategy_placeholder')"
                    :multiple="false"
                    :allow-empty="false"
                    track-by="id" 
                    label="name"
                    :showNoResults="false">
                  </multiselect>
                </div>
              </div> 
              <div class="row" v-if="strategy?.type?.id=='list'">
                <div class="col-sm-6">
                  <div class="mb-3">
                      <label for="role">{{$t("custom_strategies.sort")}}*</label>
                      <multiselect 
                        v-model="auxSort"
                        :options="sortReco"
                        :selectLabel="''"
                        :deselectLabel="''"
                        :placeholder="$t('custom_strategies.sort_placeholder')"
                        :multiple="false"
                        :allow-empty="false"
                        track-by="id" 
                        label="label"
                        :showNoResults="false"
                        @input="oSortChanged">
                      </multiselect>
                    </div>
                  </div>
                </div>
						</div>
					</div>
				</b-tab>
      </b-tabs>
		</div>
    <b-modal v-model="showSlotAssignModal" :title="$t('custom_strategies.assign_slots')" @ok.prevent="onConfirmAddSlotClicked" :okTitle="$t('common.confirm')"  :cancelTitle="$t('common.cancel')">
      <div>
        <p class="small">{{$t('custom_strategies.assign_slots_subtitle')}} </p>
        <div class="mb-3">
          <div class="form-check form-check-inline font-size-16">
              <input
                class="form-check-input"
                type="radio"
                name="paymentoptionsRadio"
                id="paymentoptionsRadio1"
                checked=""
                @input="onSlotTypeChecked('strategy')"
              />
              <label class="form-check-label font-size-13" for="paymentoptionsRadio1">
                {{$t('custom_strategies.assign_slots_strategy')}}
              </label>
            </div>
            <div class="form-check form-check-inline font-size-16">
              <input
                class="form-check-input"
                type="radio"
                name="paymentoptionsRadio"
                id="paymentoptionsRadio2"
                @input="onSlotTypeChecked('boost')"
                disabled
              />
              <label class="form-check-label font-size-13" for="paymentoptionsRadio2">
                {{$t('custom_strategies.assign_slots_boost')}}
              </label>
            </div>
        </div>
        <div class="row">
            <div class="col">
              <div class="mb-3">
                <label for="role">{{$t('custom_strategies.strategy')}}*</label>
                <multiselect 
                  v-model="auxSlot.strategy"
                  :options="recoStrategies"
                  :selectLabel="$t('common.select')"
                  :deselectLabel="$t('common.deselect')"
                  :multiple="false"
                  :allow-empty="false"
                  track-by="id" 
                  label="name"
                  :showNoResults="false">
                </multiselect>
              </div>
            </div>
          </div>
          <div class="row" v-if="auxSlot.strategy?.id=='user_affinity'">
            <div class="col">
              <div class="mb-3">
                <label for="role">{{this.$t("personalizations.experience_variable_strategy_affinity")}}*</label>
                <multiselect
                    :options="affinity_Types" 
                    v-model="auxSlot.strategy.affinity"
                    selectLabel=""
                    deselectLabel=""
                    track-by="column_name"
                    :placeholder="this.$t('personalizations.experience_variable_strategy_affinity_placeholder')"
                    label="name"
                    :allow-empty="false"
                    openDirection="bottom"
                  />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="mb-3">
                <label for="role">{{$t('custom_strategies.slots')}}*</label>
                <b-form-input type="number" v-model="auxSlot.slots"/>
              </div>
            </div>
          </div>
      </div>
    </b-modal>
    <b-modal v-model="itemImagesModal" id="modal-center" hide-footer :title="selectedItem?.name" title-class="font-18">
        <b-carousel style="text-shadow: 0px 0px 2px #000" controls indicators fade>
          <b-carousel-slide :img-src="img.url" v-for="img in selectedItem?.imgs || []" :key="img.url"></b-carousel-slide>
        </b-carousel>
    </b-modal>
		<div class="row mt-0">
			<div class="col-sm-6">
			</div>
			<!-- end col -->
			<div class="col-sm-6">
				<div class="text-end">
					<b-button variant="light" class="w-md mb-3" @click="onCancelClicked">{{$t('common.cancel')}}</b-button>
					<b-button variant="primary" class="w-md ms-3 mb-3" @click="onConfirmClicked">{{$t('common.confirm')}}</b-button>
				</div>
			</div>
			<!-- end col -->
		</div>
	</Layout>
</template>

<style scoped>

.edit-picture-button {
	height: 1.5rem !important;
	width: 1.5rem !important;
	top: -5px;
	position: absolute;
	color:#adb5bd
}

.icon-color-grey {
	color:#adb5bd
}

.table-container {
  max-height: 500px; 
  overflow-y: auto; 
  position: relative;
}

.table thead {
  position: sticky;
  top: 0;
  z-index: 10;
}

.no-select {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
</style>
